import { DeepPartial, AppConfig, Menu } from "../../../../src/app/interfaces/app-config";

export const projectConfig: DeepPartial<AppConfig> = {
    // menu: [
    //     Menu.create({
    //         id: 1,
    //         path: '/home',
    //         title: 'Shop',
    //     }),
    //     Menu.create({
    //         id: 2,
    //         path: '/tickets-saved',
    //         title: 'Mis tickets',
    //     })
    // ],
    pages: {
        login: {
            title: 'Login',
        }
    },
    swiperBanner: [
        {
            desktop: 'assets/img/general/banners/artista-web.jpg',
            mobile: 'assets/img/general/banners/artista-mobile.jpg'
        },
        // {
        //     desktop: 'assets/img/general/1banner.jpg',
        //     mobile: 'assets/img/general/BannerEvento400x400.jpg'
        // }
    ],
    swiperBannerSuccess: [
        {
            desktop: 'assets/img/general/banners/web-success.jpg',
            mobile: 'assets/img/general/banners/success.jpg'
        }
    ],
};


import { Injector } from "@angular/core";
import { EncryptionService } from "../../../../src/app/services/encryption.service";
import { environment } from "../environments/environment";

const injector = Injector.create({providers: [{provide: EncryptionService, useClass: EncryptionService, deps: []}]});
const encryptionService = injector.get(EncryptionService);

export function projectEncryptData(data: any) {
    const encryptedConfig = encryptionService.encrypt(data, environment.keyEncript, environment.ivEncript);
    return encryptedConfig;
}
